$ionicons-font-path: 'https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/fonts' !default;
$ionicons-font-family: 'Ionicons' !default;
$ionicons-version: '2.0.0' !default;
$ionicons-prefix: ion- !default;

$ionicon-var-android-contract: '\f37d';
$ionicon-var-android-expand: '\f386';

$ionicon-var-ios-arrow-left: '\f3d2';
$ionicon-var-ios-arrow-right: '\f3d3';

$ionicon-var-ios-play: '\f488';
$ionicon-var-ios-pause: '\f478';
